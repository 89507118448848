import type { GameState } from "../components/Game";

const calculateStatus = (
  // winner: null | string,
  squares: GameState,
  nextValue: string,
) => {
  // return winner
  //   ? `Winner: ${winner}`
  return squares.every(Boolean)
    ? `Scratch: Cat's game`
    : `Next player: ${nextValue}`;
};

export default calculateStatus;
