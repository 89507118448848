function setSearchParam(locationSearch: string, id: string, value: string) {
  const searchParams = new URLSearchParams(locationSearch);
  searchParams.set(id, value.toString());

  return searchParams;
}

function getSearchParam(id: string) {
  const searchParams = new URLSearchParams(window.location.search);
  const param = searchParams.get(id);

  return param;
}

function validateParamValue<T, U>(value: T, func: (arg0: T) => U) {
  if (!(func && {}.toString.call(func) === "[object Function]")) {
    return;
  }

  return func(value);
}

function resetUrl(href: string) {
  const url = new URL(href);
  url.search = "";
  // window.location.href = url.toString();
  console.log(url.toString());

  window.history.pushState(null, "", url.toString());
}

export { setSearchParam, getSearchParam, validateParamValue, resetUrl };
