import { useEffect, useState } from "react";
import ClassGroup from "classgroup";
// import { calculateWinner } from "../helpers";

export type GameHistory = string[];

const tw = ClassGroup({
  container: "flex space-x-4",
  button: {
    appearance: "rounded font-semibold text-base shadow-sm",
    layout: "flex px-4 py-2 space-x-2",
    outline:
      "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
    theme:
      "bg-black text-white disabled:bg-gray-300 hover:bg-indigo-800 focus-visible:outline-indigo-600",
  },
  steps: {
    appearance: {
      appearance: "border-2 border-black rounded font-madami",
      layout: "flex items-center justify-center w-10 h-10",
    },
  },
});

// function getWinnerFromHistory(gameHistory: GameHistory): null | string {
//   if (!gameHistory) return null;

//   const gameEnd = gameHistory[gameHistory.length - 1]
//     ?.split(",")
//     .map((i) => i || null);

//   return calculateWinner(gameEnd);
// }

export type GameHistoryNavProps = {
  gameHistory: GameHistory;
  setGameState: React.Dispatch<React.SetStateAction<any[]>>;
};

const GameHistoryNav = ({ gameHistory, setGameState }: GameHistoryNavProps) => {
  let [page, setPage] = useState(gameHistory.length - 1);
  // const winner = getWinnerFromHistory(gameHistory);

  useEffect(() => {
    setGameState(gameHistory[page]?.split(",").map((i) => i || null));
  }, [gameHistory, page, setGameState]);

  return (
    <div>
      {/* {winner && (
        <div className="flex flex-col items-center">
          🏆 Winner 🏆 <div className="tex-4xl">{winner}</div>
        </div>
      )} */}
      <div className={tw.container}>
        <button
          className={tw.button}
          onClick={() => {
            setPage((prevValue) => prevValue - 1);
          }}
          disabled={page === 0}
        >
          &larr;
        </button>
        <div className={tw.steps}>{page}</div>
        <button
          className={tw.button}
          onClick={() => {
            setPage((prevValue) => prevValue + 1);
          }}
          disabled={page === gameHistory.length - 1}
        >
          &rarr;
        </button>
      </div>
    </div>
  );
};

export default GameHistoryNav;
