import { useEffect, useState } from "react";
import ClassGroup from "classgroup";
import {
  calculateNextValue,
  calculateStatus,
  calculateWinner,
  getSearchParam,
  initStateFromUrl,
  setHistoryState,
} from "../helpers";
import GameHistoryNav, { type GameHistoryNavProps } from "./GameHistoryNav";
import type { GameHistory } from "./GameHistoryNav";
import Board from "./Board";
import Button from "./Button";

export type GameState = (string | null)[];

const tw = ClassGroup({
  section: "flex flex-col h-full w-full justify-center items-center",
  container: "flex flex-col items-center w-96",
  status: {
    appearance: "font-madami text-xl",
    layout: "flex items-center h-10",
  },
  restart: {
    appearance: "rounded font-semibold text-base shadow-sm",
    layout: "flex px-4 py-2 space-x-2",
    outline:
      "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
    theme:
      "bg-indigo-800 text-white hover:bg-indigo-900 focus-visible:outline-indigo-700",
  },
});

const Game = () => {
  const stateFromUrl: GameHistoryNavProps["gameHistory"] =
    initStateFromUrl("state") || Array(9).fill(null);
  const [gameState, setGameState] = useState(stateFromUrl);
  const [gameHistory, setGameHistory] = useState<GameHistory>([]);

  const gameOverSearchParam = getSearchParam("gameOver");
  const [gameOver, setGameOver] = useState(Boolean(gameOverSearchParam));

  useEffect(() => {
    if (!gameOver) {
      setGameHistory([gameState.toString()]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let nextValue: string;
  let winner: null | string = "";
  let status;

  if (!gameOver) {
    nextValue = calculateNextValue(gameState);
    winner = calculateWinner(gameState);
    status = calculateStatus(gameState, nextValue);
    setHistoryState("state", gameState?.toString());
  } else {
    if (!gameOverSearchParam) {
      setHistoryState("gameOver", "true");
    }
  }

  if (winner) {
    setGameOver(true);
    setHistoryState("gameOver", "true");
  }

  const selectSquare = (index: number) => {
    if (winner) return;
    if (gameState[index]) return;

    setGameState((prevGameState) => {
      prevGameState[index] = nextValue;
      return [...prevGameState];
    });

    setGameHistory((prevGameHistory) => {
      const currentGameState = gameState.toString();
      if (!prevGameHistory.includes(currentGameState)) {
        prevGameHistory.push(currentGameState);
      }
      return [...prevGameHistory];
    });
  };

  const restartGame = () => {
    setGameOver(false);
    setHistoryState("gameOver", "false");
    setGameHistory([Array(9).fill(null).toString()]);
    setGameState(Array(9).fill(null));
  };

  return (
    <section className={tw.section}>
      <div className={tw.container}>
        <div className={tw.status}>
          {gameOver ? (
            <GameHistoryNav
              gameHistory={gameHistory}
              setGameState={setGameState}
            />
          ) : (
            status
          )}
        </div>
      </div>
      <Board onSquareClick={selectSquare} squares={gameState} />
      <Button className={tw.restart} onClick={() => restartGame()}>
        Restart
      </Button>
    </section>
  );
};

export default Game;
