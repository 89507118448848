import type { GameState } from "../components/Game";
import ClassGroup from "classgroup";

type BoardProps = {
  squares: GameState;
  onSquareClick: (index: number) => void;
};

const tw = ClassGroup({
  container: {
    appearance: "bg-black",
    layout: "grid grid-rows-3 grid-cols-3 gap-2 w-96 h-96 my-12",
  },
  square: "bg-white font-madami text-5xl",
});

const Board = ({ squares, onSquareClick }: BoardProps) => {
  const renderSquare = (i: number) => {
    return (
      <button className={tw.square} onClick={() => onSquareClick(i)}>
        {squares && squares[i]}
      </button>
    );
  };

  return (
    <div className={tw.container}>
      {renderSquare(0)}
      {renderSquare(1)}
      {renderSquare(2)}
      {renderSquare(3)}
      {renderSquare(4)}
      {renderSquare(5)}
      {renderSquare(6)}
      {renderSquare(7)}
      {renderSquare(8)}
    </div>
  );
};

export default Board;
