import type { GameState } from "../components/Game";
import { getSearchParam, validateParamValue } from "./searchParams";

export default function initStateFromUrl(id: string): null | GameState {
  const searchParam = getSearchParam(id);

  if (
    searchParam &&
    validateParamValue<string, boolean>(searchParam, (value: null | string) =>
      Boolean(value && typeof value === "string" && value.length <= 17),
    )
  ) {
    return searchParam.split(",").map((val) => val || null);
  }

  return null;
}
