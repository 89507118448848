/**
 * Below you have the bare bones of a tic-tac-toe game in React
 * We'd like you to go through the following tasks:
 * 1. Implement the stubbed-out functions in order to make the game functional
 * 2. Make the game state persistent between refreshes
 * 3. Implement a persistent game history, that allows the user to step back and forth
 *    through each state of the game
 */
import Game from "./components/Game";

export default function App() {
  return <Game />;
}
