import { ReactNode } from "react";

type ButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
  children: ReactNode;
  onClick: () => void;
};

export default function Button({ children, ...props }: ButtonProps) {
  return <button {...props}>{children}</button>;
}
